<template>
  <div style="background-color: #FFFFFF; padding-bottom: 20px; position: relative;">
    <div style="position: absolute; top: 30px; right: 20px;font-size: 14px;top: 15px;"><span>您可以关联2个账号，关联的手机号码需登录过E查工程</span></div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="关联账户" name="associated-account">
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin: 20px;">
      <el-col :span="24">
        <div style="display: flex; justify-content: space-between;">
          <div class="aa-tool-bar">
            <el-button type="primary" @click="handlerAdd">新增关联账户</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="aa-list">
          <el-table :data="tableData" border :header-cell-style="headerColor">
            <el-table-column type="index" label="序号" width="200" align="center">
            </el-table-column>
            <el-table-column prop="remarkName" label="用户名称" align="center">
            </el-table-column>
            <el-table-column prop="vipTel" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="vipName" label="微信名称" align="center">
            </el-table-column>
            <el-table-column label="操作" width="80px" align="center">
              <template #default="scope">
                <span class="btn-hover-foucs" @click="handlerDelRelevancy(scope.$index, scope.row)">解除关联</span>
              </template>
            </el-table-column>
            <template #empty style="width: 100%;">
              <el-empty :image-size="200"></el-empty>
            </template>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
  <el-dialog
    v-model="isVipDialogVisible"
    width="500px"
    append-to-body="true">
    <div style="width: 100%;font-size: 30px;color: #333;"><span>开通<b style="color: blue;">VIP</b>会员即可进行关联账户</span></div>
    <template #footer>
      <div>
        <el-button type="primary" style="width: 100%;">立即开通</el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    title="新增关联账户"
    v-model="isAccountDialogVisible"
    width="500px"
    class="aa-dialog"
    append-to-body="true">
    <!-- <hr style="margin-bottom: 5px;"/> -->
    <el-form :model="relevancyUserForm" :rules="relevancyUserRules" ref="relevancyUserForm" label-width="100px">
      <el-form-item label="用户名称" prop="userName">
        <el-input v-model="relevancyUserForm.userName" placeholder="请输入用户名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phoneNumber">
        <el-input v-model="relevancyUserForm.phoneNumber" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verifyCode">
        <el-input placeholder="请输入短信验证码" v-model="relevancyUserForm.verifyCode">
          <template #append>
            <el-link type="primary" href="javascript:void(0)" :disabled="!isCodeEnable" @click="startCountdown">
              获取验证码
            </el-link>
            <vue-countdown :time="time" v-slot="{ seconds }" v-if="counting" :autoStart="true"
                           @end="onCountdownEnd">
              （{{ seconds }} 秒）
            </vue-countdown>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="微信名称" prop="weixin">
        <el-input v-model="relevancyUserForm.weixin" placeholder="微信名称" disabled></el-input>
      </el-form-item>
      <!-- <hr style="margin-bottom: 10px;"/> -->
      <el-form-item>
        <div style="display: flex; justify-content: flex-end;">
          <el-button style="width: 100px;" @click="handlerCancel">取消</el-button>
          <el-button type="primary" style="width: 100px;" :loading="clickLoading" @click="handlerSave">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { getByTel, getRelevancyUser, addRelevancyUser, delRelevancyUser } from '@/api/user.js'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'

export default {
  name: 'associated-account',
  components: { VueCountdown },
  data () {
    var validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空！'))
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(value)) {
        callback(new Error('请输入正确的手机号码！'))
      } else {
        callback()
      }
    }
    return {
      clickLoading: false,
      tableData: null,
      isVipDialogVisible: false,
      isAccountDialogVisible: false,
      time: 60 * 1000,
      counting: false, // 倒计时
      isPassing: false, // 滑动验证是否通过
      isCodeEnable: true, // 验证码获取功能是否可用
      relevancyUserId: '', // 关联账户Id
      relevancyUserForm: {
        userName: '',
        phoneNumber: '',
        verifyCode: '',
        weixin: ''
      },
      relevancyUserRules: {
        userName: [{
          required: true,
          message: '请输入用户名称',
          trigger: 'blur'
        },
        {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }],
        phoneNumber: [{
          required: true,
          validator: validatePhoneNumber,
          trigger: 'change'
        }],
        verifyCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    headerColor: function ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    handlerAdd () {
      // this.isVipDialogVisible = true
      if (this.tableData.length >= 2) {
        ElMessage.error({
          message: '您的关联用户已达上限',
          offset: 20
        })
      } else {
        this.isCodeEnable = true
        this.time = 60 * 1000
        this.counting = false
        this.isAccountDialogVisible = true
      }
    },
    loadData () {
      const params = {
        userId: JSON.parse(this.$store.getters.userInfo).userId
      }
      getRelevancyUser(params).then(result => {
        this.tableData = result.data
      })
    },
    onCountdownEnd () {
      this.time = 60 * 1000
      // this.isPassing = false
      this.counting = false
      this.isCodeEnable = true
    },
    startCountdown () {
      this.$refs.relevancyUserForm.validateField('phoneNumber', (error) => {
        if (!error) {
          const params = {
            tel: this.relevancyUserForm.phoneNumber
          }
          getByTel(params)
            .then(result => {
              if (result.code === 0) {
                this.relevancyUserForm.weixin = result.data.vipName
                this.relevancyUserId = result.data.userId
                this.isCodeEnable = false
                this.counting = true
                this.getVerifyCode()
              } else {
                ElMessage.error({
                  message: result.msg,
                  offset: 20
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    passCallback () {
      this.isCodeEnable = true
    },
    getVerifyCode () {
      const data = {
        dateBackgroundUrl: '/bid/verifyCode/registergetVerifyCode',
        dateBackgroundRequestType: 'post',
        data: {
          mobile: this.relevancyUserForm.phoneNumber,
          verifyTypeCode: '32' // 30
        }
      }
      forwardBID(data)
        .then(result => {
        })
        .catch(error => {
          console.log(error)
        })
    },
    handlerSave () {
      this.clickLoading = true
      const datas = {
        dateBackgroundUrl: '/bid/verifyCode/checkVerifycode',
        dateBackgroundRequestType: 'post',
        idToken: this.$store.getters.idToken,
        data: {
          mobile: this.relevancyUserForm.phoneNumber,
          verifyTypeCode: 9,
          verification: this.relevancyUserForm.verifyCode
        }
      }
      forwardBID(datas).then(result => {
        const res = result
        console.log(res)
        if (res.code === 0) {
          const data = {
            userId: JSON.parse(this.$store.getters.userInfo).userId,
            remarkName: this.relevancyUserForm.userName,
            userName: this.relevancyUserForm.weixin,
            relevancyUserId: this.relevancyUserId
          }
          addRelevancyUser(data).then(result => {
            if (result.code === 0) {
              ElMessage.success({
                message: '关联成功！',
                offset: 20
              })
              this.relevancyUserForm.userName = ''
              this.relevancyUserForm.phoneNumber = ''
              this.relevancyUserForm.verifyCode = ''
              this.relevancyUserForm.weixin = ''
              this.$refs.relevancyUserForm.resetFields()
              this.isAccountDialogVisible = false
              this.clickLoading = false
              this.loadData()
            } else {
              this.clickLoading = false
              ElMessage.error({
                message: result.msg,
                offset: 20
              })
            }
          })
        } else {
          this.clickLoading = false
          ElMessage.error({
            message: '验证码错误，请重新输入！',
            offset: 20
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handlerCancel () {
      this.relevancyUserForm.userName = ''
      this.relevancyUserForm.phoneNumber = ''
      this.relevancyUserForm.verifyCode = ''
      this.relevancyUserForm.weixin = ''
      this.$refs.relevancyUserForm.resetFields()
      this.isAccountDialogVisible = false
    },
    handlerDelRelevancy (index, row) {
      const loading = this.$loading({
        lock: true,
        text: '请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        id: row.blRelevancyUserId
      }
      delRelevancyUser(data).then(result => {
        if (result.code === 0) {
          ElMessage.success({
            message: '解除关系成功！',
            offset: 20
          })
          loading.close()
          this.loadData()
        } else {
          loading.close()
          ElMessage.error({
            message: '解除关系失败！',
            offset: 20
          })
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 120px;
}

.aa-tool-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

/deep/ .el-dialog__body {
  padding: 0px !important;
}

/deep/.el-dialog /deep/ .el-dialog__body {
  padding: 0px !important;
}
.btn-hover-foucs {
  color: #5D6FE9;
  cursor: pointer;
  text-decoration: none;
}
.btn-hover-foucs:hover {
  font-weight: bold;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
